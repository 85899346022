.sentitem select {
  appearance: none !important; /* Remove default arrow in modern browsers */
  -webkit-appearance: none !important ;
  -moz-appearance: none !important;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')
    no-repeat center;
  background-color: #fff;
  width: 20px;
}
.sentitem select::-ms-expand {
  display: none; /* Remove default arrow in IE */
}
.sentitem select option {
  background-color: #fff; /* Change to white */
  color: #333; /* Text color */
}

/* Remove blue color on focus */
.sentitem select:focus {
  outline: none;
  border-color: #777; /* Border color when focused */
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  display: none;
}
