.sidebar-icon:hover {
  color: #fff;
}
.sidebar-icon:hover svg {
  color: #fff;
}
/* Sidebar styles */
.sidebar {
  transition: transform 0.3s ease;
}

.sidebar-closed {
  transform: translateX(-100%);
}

/* Adjust the content area to take up full width when the sidebar is closed */
.content {
  transition: margin-left 0.3s ease;
}

.content-right {
  height: calc(100vh - 65px);
  overflow: auto;
}

.content-expanded {
  width: 220px;
}

.content-collapsed {
  width: 0px;
}
.sidebar-box {
  transition: width 0.3s ease-in-out;
}
.sidebar-main {
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.overflow{
  overflow-y: scroll;
}