.login {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../../images/back-img.jpg");
  background-repeat: repeat;
  background-size: cover;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  -webkit-background-color: transparent;
}

input:-moz-autofill {
  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  background-color: transparent;
}

input:-ms-autofill {
  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  background-color: transparent;
}
.login input:-internal-autofill-selected {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
}
input[type="checkbox"].remember {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px; /* Adjust width */
  height: 15px; /* Adjust height */
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 5px;
  padding: 0;
}
