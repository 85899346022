@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 10px 15px;
}
tr {
  border-bottom: 1px solid #eee;
}
tr:last-child {
  border-bottom: none;
}
.search-results {
  position: absolute;
  /* left: 0; */
  background: #fff;
  z-index: 9999;
  max-width: 593px;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 10px;
  top: 26px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.list-disc {
  padding-left: 0;
  list-style: none;
 
}

.search-results {
  position: absolute;
  /* left: 0; */
  background: #fff;
  z-index: 9999;
  max-width: 593px;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 10px;
  top: 26px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0 !important;
}

.list-disc li a {
  text-decoration: none;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}

.list-disc li p {
  font-size: 14px;
  /* color: #000; */
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}

.list-disc li {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 25px;
}

.list-disc li::before {
  position: absolute;
  content: "\2709";
  left: 0;
  color: #727272;
  top: 0;
  font-family: FontAwesome;
  font-size: 18px;
  /* transform: translateY(-50%); */
}

a {
  text-decoration: none !important;
}

.searchicon{
  background: none !important;
}

@media screen and (max-width: 767px) {

  .content-table {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .content-right {
    padding: 16px;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  .inbox-single, .inbox-single * {
    visibility: visible;
  }
  .inbox-single {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@media(max-width: 592px){
  .reply-dropdown{
    left: -100px !important;
  }
}

@media(max-width: 548px){
  .layout-mobile{
    margin-left: 0px !important;
  }
}


@media(max-width: 420px){
  .parent-dropdown{
    left: 50px !important;
  }
}

@media(max-width: 495px){
  .responsive-dropdown{
    top: 125px !important;
  }
}

@media(max-width: 377px){
  .responsive-dropdown{
    top: 140px !important;
  }
}


